.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media screen and (min-width: 990px) {
  .accordion-body img {
    margin: 0px auto;
    height: 200px;
    max-width: 100%;
    margin-left: 10% !important;
  }
  .card-text .card-body {
    overflow-y: hidden;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
@media screen and (max-width: 1568px) {
  .sidebar {
    display: none !important;
  }
  .dropdown-menu {
    display: block !important;
  }
  html {
    width: 100% !important;
    margin: 20px auto;
    word-wrap: break-word;
  }
  .col-md-7 {
    max-width: 95% !important;
    margin: 20px auto;
  }
  img {
    max-width: 100%;
    height: auto !important;
  }
}
@media screen and (min-width: 1569px) {
  .sidebar {
    display: block !important;
  }
  .dropdown-toggle {
    display: none !important;
  }
  .row {
    padding-top: 20px;
  }
}
.App-link {
  color: #61dafb;
}
a {
  text-overflow: ellipsis;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
