* {
  font-family: "Times New Roman", Times, serif;
}
.row {
  max-width: 98%;
  margin: 0px auto;
}
a {
  text-decoration: none;
}
@media screen and (max-width: 900px) {
  .row {
    width: 100%;
  }
  .col-md {
    width: 98% !important;
    margin: 0px auto;
    margin-left: 5%;
  }
  .col-md-2 {
    width: 100% !important;
    margin-bottom: 20px;
    margin-left: 3%;
  }
  .col-md .row {
    width: 100%;
  }
  .col-md-4 {
    margin-left: 2%;
  }
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.accordion-body {
  overflow: hidden;
}
.accordion-button.collapsed {
  word-break: break-word;
}
.accordion-header {
  text-overflow: ellipsis;
}
.card-body .card-text {
  max-height: 200px;
  overflow: hidden !important;
}
.accordion-body {
  position: relative;
}

.card .btn {
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
}
.accordion-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  max-width: 100%;
}
img {
  margin: 0px auto !important;
}
