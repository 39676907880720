.card-body {
  text-decoration: none;
}
a {
  text-decoration: none;
  text-overflow: ellipsis;
}
.accordion-button.collapsed {
  word-break: break-word;
}
.card-text {
  text-decoration: none !important;
  min-height: 50px;
}
