@media screen and (max-width: 1400px) {
  .main-container {
    width: 100%;
    margin: 0px auto;
  }
  .w-100 {
    margin: 0px auto;
    margin-bottom: 20px;
  }
  .row.col-md-5 {
    width: 100%;
    margin-bottom: 20px;
  }
  .card .btn {
    margin: 0px auto !important;
    transform: translateX(0);
  }
  .card-img-top {
    max-width: 80%;
  }
}
@media screen and (min-width: 1401px) {
  .main-container {
    width: 60%;
  }
  .main-container {
    margin: 10% auto;
  }
  .row {
    justify-content: space-between;
  }
  .card .btn {
    margin: 0px auto !important;
    transform: translateX(0);
  }
  .card-img-top {
    max-width: 80%;
  }
}
